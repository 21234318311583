<template>
  <div>
    <table style="table-layout: fixed" width="100%">
      <template v-for="(file, index) in files">
        <tr
          :key="`file-row-${index}`"
          :class="{
            'table-alternate-row': index % 2,
            'd-flex  flex-column': !allowAddMore && directionColumn,
          }"
        >
          <td
            style="vertical-align: baseline"
            :class="[!allowAddMore && directionColumn ? 'd-flex  flex-column' : 'pr-2']"
          >
            <v-file-input
              :id="`document-file-${index}`"
              placeholder="Select File"
              outlined
              :accept="accept"
              prepend-icon=""
              prepend-inner-icon="mdi-attachment"
              hide-details
              :show-size="showSize"
              v-model="file.file"
              v-on:change="updateFile(index, $event)"
              :rules="rules(file.file, `File ${index + 1}`, true)"
              :class="[{ required: required && !file.file }, ...customClassArr]"
              v-on:click:clear="updateFile(index, $event), $emit('clearFile')"
            ></v-file-input>
          </td>
          <td
            :class="[
              !allowAddMore && directionColumn
                ? 'd-flex  flex-column'
                : 'px-2' && alignColumn
                ? 'pt-2'
                : 'px-2',
            ]"
          >
            <TextInput
              :id="`document-name-${index}`"
              counter="50"
              :rules="[(v) => (v ? v.length <= 50 : true) || `File Name maximum 50 characters`]"
              v-model="file.name"
              hideTopMargin
              placeholder="File Name"
              customClass="mt-0"
              :suffix="file.suffix"
            ></TextInput>
          </td>
          <td width="10%" align="center" valign="top" class="pl-2" v-if="allowAddMore">
            <v-btn
              v-on:click="removeFile(index)"
              color="red lighten-1 white--text"
              :class="customClassArr"
              icon
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <tr v-if="allowAddMore">
        <td class="pr-2"></td>
        <td class="pr-2">
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-4 white--text"
              :class="customClassArr"
              tile
              depressed
              v-on:click="addMore()"
            >
              <v-icon>mdi-plus</v-icon> Add More...
            </v-btn>
          </div>
        </td>
        <td class="pr-2"></td>
      </tr>
    </table>
  </div>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import { SET_ERROR } from "@/core/services/store/common.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { POST } from "@/core/services/store/request.module";
export default {
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      documents: [],
      attachLoading: false,
      files: [
        {
          file: null,
          name: null,
          suffix: null,
          size: null,
          id: null,
        },
      ],
    };
  },
  props: {
    showSize: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    customClassArr: {
      type: Array,
      default() {
        return ["mt-2"];
      },
    },
    allowAddMore: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    directionColumn: {
      type: Boolean,
      default: false,
    },
    alignColumn: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TextInput,
  },
  methods: {
    rules(vmodel, label, is_file) {
      if (this.required) {
        if (is_file) {
          return [this.vrules.requiredFile(vmodel, label)];
        } else {
          return [this.vrules.required(vmodel, label)];
        }
      }
      return [];
    },

    addMore() {
      this.files.push({
        file: null,
        base64: null,
        name: null,
        suffix: null,
        size: null,
        id: null,
      });
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
    async updateFile(index, file) {
      if (file && file.name) {
        let fileExtension = file.type.split("/");

        fileExtension = fileExtension[0];
        if (fileExtension != this.accept) {
          if (this.accept == "application") {
            this.$store.commit(SET_ERROR, [{ model: true, message: `Only Documents allowed` }]);
          } else {
            this.$store.commit(SET_ERROR, [
              { model: true, message: `Only ${this.accept} allowed` },
            ]);
          }

          this.files = [
            {
              file: null,
              name: null,
              suffix: null,
              size: null,
              id: null,
            },
          ];

          return false;
        }
      }

      if (file && file.name) {
        this.files[index].base64 = await this.convert_base_64(file);
        this.files[index].name = file.name.split(".").slice(0, -1).join(".");
        this.files[index].suffix = `${file.name.split(".").pop()}`;
        this.files[index].size = file?.size;
        this.files[index].type = file?.type;
        this.files[index].id = file?.id;
        // console.log(this.files[index].type)
        this.files[index].file_id = await this.uploadFiles(this.files[index]);
        console.log(this.files[index].file_id);
      } else {
        this.files[index].base64 = null;
        this.files[index].name = null;
        this.files[index].suffix = null;
        this.files[index].size = file?.size;
        this.files[index].type = file?.type;
        this.files[index].id = file?.id;
      }

      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    },
    uploadFiles(file) {
      console.log(file);
      let file_id = 0;
      if (file && file?.type) {
        console.log(file?.type);
        let fileExtention = file?.type.split("/");
        fileExtention = fileExtention[0];
        if (fileExtention != "image") {
          ErrorEventBus.$emit("update:error", InitializeError("Only image allowed"));
          return false;
        }
      }
      this.isUploading = true;
      if (file && file?.type) {
        this.$store
          .dispatch(POST, {
            url: `upload-image`,
            data: file,
          })
          .then((data) => {
            this.document = data.id;
            file_id = data.id;
            // this.document = { ...this.document, id: 'k'+this.documents.length + 1 };

            this.documents.push(data.id);
            this.$emit("uploadData", this.documents);
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
          });
      }
      return file_id;
    },
  },
  mounted() {
    this.files = [
      {
        file: null,
        name: null,
        suffix: null,
      },
    ];

    this.$nextTick(() => {
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    });
  },
};
</script>
<style scoped>
.v-application .v-btn.v-size--default {
  min-width: 20px;
}
</style>
