<template>
  <v-layout>
    <v-flex class="max-width-80px">
      <SelectInput
        :id="stringUnique"
        hide-details
        :loading="loading"
        :disabled="disabled"
        item-text="text"
        item-value="value"
        :items.sync="countries"
        v-model="countryCode"
        :class="selectClass"
      ></SelectInput>
    </v-flex>
    <v-flex>
      <v-text-field
        :id="id"
        ref="phoneInput"
        :label="label"
        :loading="loading"
        :disabled="disabled"
        :hide-details="hideDetails"
        outlined
        :rules="rules"
        :prefix="getPrefix"
        v-mask="'##########'"
        class="mt-3 pt-0"
        :class="{ required: !textinput }"
        v-model="textinput"
        :placeholder="placeholder"
      >
      </v-text-field>
    </v-flex>
  </v-layout>
</template>
<script>
import SelectInput from "@/view/components/SelectInput.vue";
import { trimStart } from "lodash";
export default {
  name: "text-phone-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    selectClass: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: [
        {
          text: "MY",
          value: "+60",
        },
        {
          text: "SG",
          value: "+65",
        },
      ],
      countryCode: "+65",
      textinput: null,
    };
  },
  components: {
    SelectInput,
  },
  watch: {
    value() {
      if (this.value) {
        const array = this.value.split("-");
        this.countryCode = array[0] ? array[0] : null;
        this.textinput = array[1] ? array[1] : null;
      }
    },
    countryCode() {
      const value = trimStart(this.textinput, "-");
      this.$emit("input", `${this.countryCode}-${value}`);
    },
    textinput() {
      const value = trimStart(this.textinput, "-");
      this.$emit("input", `${this.countryCode}-${value}`);
    },
  },
  computed: {
    getPrefix() {
      return `${this.countryCode}-`;
    },
  },
  mounted() {
    if (this.value) {
      const array = this.value.split("-");
      this.countryCode = array[0] ? array[0] : null;
      this.textinput = array[1] ? array[1] : null;
    }
  },
};
</script>
